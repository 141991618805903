import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type SessionService from 'ember-simple-auth/services/session';
import type { IntlService } from 'ember-intl';
import type Transition from '@ember/routing/transition';
import Intercom from '@intercom/messenger-js-sdk';
import config from 'dashboard/config/environment';
import { isTesting, macroCondition } from '@embroider/macros';
import { chooseBestFitLocale } from '@showbie/utilities';
import { SUPPORTED_LOCALES } from 'dashboard/constants';

// https://www.npmjs.com/package/@intercom/messenger-js-sdk#installation
const INTERCOM_SETTINGS = {
  api_base: 'https://api-iam.intercom.io',
  app_id: config.intercom.app_id,
};

if (macroCondition(!isTesting())) {
  Intercom(INTERCOM_SETTINGS);
}

export default class ApplicationRoute extends Route {
  @service declare session: SessionService;
  @service declare intl: IntlService;

  async beforeModel(transition: Transition): Promise<void> {
    super.beforeModel(transition);
    // https://github.com/simplabs/ember-simple-auth/blob/master/guides/upgrade-to-v4.md
    await this.session.setup();

    let locale = 'en-us';
    if (!this.session.isAuthenticated) {
      // choose locale based on browser settings for non-authenticated users
      locale = chooseBestFitLocale(SUPPORTED_LOCALES);
    }

    // https://ember-intl.github.io/ember-intl/versions/v5.7.0/docs/quickstart#4-configure-ember-intl
    this.intl.setLocale([locale]);
  }
}
