import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type { School, Teacher } from 'dashboard/types';

export enum FeedbackModalType {
  REMOVE_TEACHER_CONFIRMATION = 'REMOVE_TEACHER_CONFIRMATION',
  DEACTIVATE_STUDENT_CONFIRMATION = 'DEACTIVATE_STUDENT_CONFIRMATION',
  ACTIVATE_STUDENT_CONFIRMATION = 'ACTIVATE_STUDENT_CONFIRMATION',
  ACTIVATE_TEACHER_CONFIRMATION = 'ACTIVATE_TEACHER_CONFIRMATION',
  LICENSE_UPGRADE_EXCEPTION = 'LICENSE_UPGRADE_EXCEPTION',
  DOWNGRADE_TEACHER_LICENSE = 'DOWNGRADE_TEACHER_LICENSE',
  SIGN_IN_QR_EXPORT_CONFIRMATION = 'SIGN_IN_QR_EXPORT_CONFIRMATION',
  SIGN_IN_QR_EXPORT_ERROR = 'SIGN_IN_QR_EXPORT_ERROR',
  START_SYNC_GENERIC_ERROR = 'START_SYNC_GENERIC_ERROR',
  START_SYNC_ALREADY_RUNNING_ERROR = 'START_SYNC_ALREADY_RUNNING_ERROR',
}

export type FeedbackModalTypeKey = keyof typeof FeedbackModalType;

// type NotifyButtonAction = 'ok' | 'cancel';
// type NotifyButtonConfig = {
//   label: string;
//   action: NotifyButtonAction;
//   tone?: 'critical';
// };
// type NotifyButton = NotifyButtonAction | NotifyButtonConfig;

// const OK_BUTTON: NotifyButtonConfig = {
//   label: 'OK',
//   action: 'ok',
// };

// const CANCEL_BUTTON: NotifyButtonConfig = {
//   label: 'Cancel',
//   action: 'cancel',
// };

type FeedbackDialogOptionsIntlArgs = {
  count: number;
  teacher?: Teacher;
  school?: School['name'];
};

type FeedbackDialogOptions = {
  onConfirm?: () => void;
  intlArgs?: FeedbackDialogOptionsIntlArgs;
};

export default class NotifyService extends Service {
  @tracked showFeedback = false;
  @tracked feedbackType?: FeedbackModalTypeKey;
  @tracked intlArgs?: FeedbackDialogOptionsIntlArgs;
  #onConfirm?: () => void;

  // @tracked visible = false;
  @tracked title = 'title';
  @tracked message = 'message';
  // @tracked buttons: NotifyButtonConfig[] = [];

  feedback(
    type: FeedbackModalTypeKey,
    { onConfirm, intlArgs }: FeedbackDialogOptions = {}
  ) {
    this.feedbackType = type;
    this.intlArgs = intlArgs ?? { count: 1 };
    this.#onConfirm = onConfirm;

    // reveal dialog
    this.showFeedback = true;
  }

  @action
  performAction() {
    this.#onConfirm?.();
    this.closeFeedback();
  }

  @action
  closeFeedback() {
    this.feedbackType = undefined;
    this.showFeedback = false;
    this.intlArgs = { count: 1 };
  }
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('service:notify')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('notify') declare altName: NotifyService;`.
declare module '@ember/service' {
  interface Registry {
    notify: NotifyService;
  }
}
