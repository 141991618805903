import {
  BrowserClient,
  BrowserConfig,
  Identify,
  ValidPropertyType,
} from '@amplitude/analytics-types';
import type { AnalyticsPlugin } from 'analytics';

/**
 * Amplitude analytics plugin for Showbie.
 * This plugin assumes that the consuming application has `@amplitude/analytics-browser`
 * defined as a dependency.
 *
 * This is loosely based on the https://getanalytics.io/plugins/amplitude/
 * plugin but uses the newer `@amplitude/analytics-browser` package.
 *
 * @see https://getanalytics.io/plugins/writing-plugins/
 */
export default function amplitudePlugin(userConfig = {}): AnalyticsPlugin {
  // Flag is set to true after amplitude client instance is initialized.
  let amplitudeInitCompleted = false;
  let amplitude: BrowserClient;

  return {
    name: 'amplitude-showbie',

    /** @see https://getanalytics.io/api/#configuration */
    config: userConfig,

    /**
     * Dynamically import the Amplitude SDK and initialize it with the provided API key.
     * @see https://amplitude.com/docs/sdks/analytics/browser/migrate-from-javascript-sdk-to-browser-sdk-1-0#defer-initialization
     */
    // @ts-expect-error types not exported from `analytics`
    initialize: async ({ config }) => {
      // const { apiKey, options = {} } = config;
      const apiKey: string = config.apiKey;
      const options: BrowserConfig = config.options;

      if (!apiKey) {
        throw new Error(
          '[amplitude-showbie] Amplitude project API key is not defined'
        );
      }
      if (options && typeof options !== 'object') {
        throw new Error(
          '[amplitude-showbie] Amplitude SDK options must be an object'
        );
      }

      try {
        amplitude = await import('@amplitude/analytics-browser');
      } catch (error) {
        throw new Error(
          '[amplitude-showbie] Amplitude SDK could not be loaded. Please ensure that `@amplitude/analytics-browser` is installed as a dependency.'
        );
      }

      await amplitude.init(apiKey, options).promise;
      amplitudeInitCompleted = true;
    },

    loaded: () => amplitudeInitCompleted,

    /** @see https://getanalytics.io/api/#analyticsidentify */
    // @ts-expect-error types not exported from `analytics`
    identify: ({ payload: { userId, traits = {} } }) => {
      amplitude?.setUserId(userId);

      const userProperties = Object.entries(traits);

      if (userProperties.length > 0) {
        // @ts-expect-error https://amplitude.com/docs/sdks/analytics/browser/migrate-from-javascript-sdk-to-browser-sdk-1-0#identify
        const identify: Identify = new amplitude.Identify();
        userProperties.forEach(([key, value]) => {
          identify.set(key, value as ValidPropertyType);
        });
        amplitude.identify(identify);
      }
    },

    /** @see https://getanalytics.io/api/#analyticstrack */
    // @ts-expect-error types not exported from `analytics`
    track: ({ payload: { event, properties = {} } }) => {
      // { config, instance, payload }
      amplitude?.track(event, properties);
    },

    /** @see https://getanalytics.io/api/#analyticspage */
    page: () => {
      console.debug('[amplitude-showbie] does not support page tracking.');
    },

    /** @see https://getanalytics.io/api/#analyticsreset */
    reset: () => {
      console.debug('[amplitude-showbie] reseting Amplitude');
      amplitude?.reset();
    },
  };
}
