import Route from '@ember/routing/route';
import { type RegionResolutionResponse } from '@showbie/authentication';
import { tracked } from 'tracked-built-ins';

export * from './shoelace-extras';

/**
  Get the resolved type of an item.

  - If the item is a promise, the result will be the resolved value type
  - If the item is not a promise, the result will just be the type of the item

  @see https://docs.ember-cli-typescript.com/ember/routes
  @see https://docs.ember-cli-typescript.com/cookbook/working-with-route-models
 */
export type Resolved<P> = P extends Promise<infer T> ? T : P;

/** Get the resolved model value from a route. */
export type ModelFrom<R extends Route> = Resolved<ReturnType<R['model']>>;

export interface Error {
  __typename: string;
  message: string;
  code: string;
  status: string;
}

export interface IdentifiableError extends Error {
  __typename: 'IdentifiableError';
  identifier: string;
  identifierType: string;
  message: string;
  code: string;
}

export type InstitutionType = 'School' | 'District' | 'Business';
export type InstitutionMember = Auditor | CourseMember;
export type CourseMember = Teacher | Student;
export type SelectableType = InstitutionMember | SyncUser;

export interface ISelectableItem {
  item: SelectableType;
  id?: string;
  selected?: boolean;
}

export class SelectableItem {
  id: string;
  @tracked item: SelectableType;
  @tracked selected: boolean;

  constructor({ item, selected = false, id = '' }: ISelectableItem) {
    this.id = id;
    this.item = item;
    this.selected = selected;
  }
}

export class SelectableTeacher extends SelectableItem {
  @tracked declare item: Teacher;
}

export class SelectableStudent extends SelectableItem {
  @tracked declare item: Student;
}

export class SelectableSyncUser extends SelectableItem {
  @tracked declare item: SyncUser;
}

export interface School {
  id: string;
  name: string;

  type: InstitutionType;
  listed?: boolean;
  teachers?: Teacher[];
  district: string;
  teacherCountClosure?: number;

  // teacher license information
  remainingLicenseCount?: number;
  maxLicenseCount?: number;
  remainingLicenseCountInClosure?: number;
  maxLicenseCountInClosure?: number;
  districtLicencesAvailable?: number;
  incomingSubscriptionAllotments?: number;
  numberOfSeatsInSubscriptions?: number;
  outgoingSubscriptionAllotments?: number;

  // purchaser location properties
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;

  // sync properties
  syncDetails?: PurchaserSyncDetails;

  // sync properties
  settings?: PurchaserSettings;
}

export interface User {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  locale?: string;
  picturePath: string;
  largePicturePath: string;
  purchasers: School[];
  managedPurchasers: School[];
}

export interface Auditor {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  avatarUrl?: string;
}

export interface Teacher {
  __typename: 'Teacher';
  accountStatus: AccountStatus;
  avatarMediumUrl?: string;
  avatarSmallUrl?: string;
  email?: string;
  emailStatus: EmailStatus;
  firstName?: string;
  id: string;
  lastUserAccess: number;
  lastName?: string;
  linkedPurchaserId?: string;
  linkedPurchaserName?: string;
  managed: boolean;
  name?: string;
  preferredDisplayName: string | undefined;
  pro: boolean;
  schoolVerified: boolean;
  soloPro: boolean;
}

export interface Student {
  __typename: 'Student';
  accountStatus: AccountStatus;
  avatarMediumUrl?: string;
  avatarSmallUrl?: string;
  email?: string;
  emailStatus: EmailStatus;
  firstName?: string;
  id: string;
  lastUserAccess: number;
  lastName?: string;
  linkedPurchaserId?: string;
  linkedPurchaserName?: string;
  managed: boolean;
  name?: string;
  preferredDisplayName: string | undefined;
  schoolVerified: boolean;
  username: string;
  signedUpWithSSO: boolean;
}

export interface PurchaserSyncDetails {
  id: string;
  name: string;
  active: boolean;
}

export interface PurchaserSettings {
  allowJoinExternalClasses?: boolean;
}

export interface SyncUser {
  action: MisSyncUserAction;
  email?: string;
  emailStatus: EmailStatus;
  firstName?: string;
  id: string;
  lastName?: string;
  purchaserId?: string;
  name?: string;
  schoolDifferent?: boolean;
  showbieId?: string;
  username?: string;
  userType?: string;
  yearGroup?: string;
}

export interface Course {
  id: string;
  name?: string;
  code?: string;
  teacherCount?: number;
  studentCount?: number;
  teacher?: Teacher;
}

export interface ShowbieSessionData {
  authenticator: string;
  fingerprint: string;
  id: number;
  intercomHMAC: string;
  intercomUserData: string;
  liveEventsRegistrationUrl: string;
  requestToken: string;
  token: string;
  user: string;
  region: RegionResolutionResponse;
}

export interface BlingModeSessionData {
  token: string;
  authenticator?: string;
  fingerprint?: string;
  intercomHMAC?: string;
  region: RegionResolutionResponse;
}

export type ShowbieRegionCode = 'us-east-1' | 'eu-north-1';

export type BlingModeParams = {
  bling_token: string;
};

export interface UserLoginCredentials {
  userId: string;
  password: string;
}

export interface UserOAuthCredentials {
  accessToken: string;
  provider: SSOProvider;
  idToken?: string;
}

export interface UserOIDCCredentials {
  provider: SSOProvider;
  idToken: string;
}

export type OAuthGrantType =
  | 'code_flow'
  | 'refresh_token'
  | 'access_token'
  | 'jwt'
  | 'oidc';

export enum SSOProvider {
  FEIDE = 'FEIDE2',
  GOOGLE = 'GOOGLE',
  MICROSOFT = 'MICROSOFT',
}

export enum AccountStatus {
  PENDING = 'PENDING',
  SYNCED = 'SYNCED',
  MEMBER = 'MEMBER',
  DEACTIVATED = 'DEACTIVATED',
}

export enum EmailStatus {
  PENDING = 'PENDING',
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
}

export enum MisSyncUserAction {
  CREATE_NEW_RECORD = 'CREATE_NEW_RECORD',
  DO_NOT_SYNC = 'DO_NOT_SYNC',
}

export type UserAuthenticationCredentials = UserLoginCredentials &
  UserOAuthCredentials;

export type ShowbieOAuthRequestIntent = 'get' | 'create' | 'link';
export type ShowbieUserRole = 1 | 2 | 3;
export interface ShowbieOIDCRequestBody {
  fingerprint: string;
  grant_type: OAuthGrantType;
  identity: string;
  intent: ShowbieOAuthRequestIntent;
  provider: SSOProvider;
  user_role?: ShowbieUserRole;
}

/** @see https://github.com/showbie/showbieservice/wiki/Sessions#oauth */
export interface ShowbieOAuthRequestBody {
  assertion: string;
  fingerprint: string;
  grant_type: OAuthGrantType;
  id_token?: string;
  intent: ShowbieOAuthRequestIntent;
  provider: SSOProvider;
  user_role?: ShowbieUserRole;
}

export enum TeacherSpecificActionType {
  ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT',
  DOWNGRADE_TEACHER_LICENSE = 'DOWNGRADE_TEACHER_LICENSE',
  REMOVE_TEACHER_FROM_SCHOOL = 'REMOVE_TEACHER_FROM_SCHOOL',
  RESEND_ACCOUNT_ACTIVATION = 'RESEND_ACCOUNT_ACTIVATION',
  RESEND_EMAIL_VERIFICATION = 'RESEND_EMAIL_VERIFICATION',
  RESET_TEACHER_PASSWORD = 'RESET_TEACHER_PASSWORD',
  UPGRADE_TEACHER_LICENSE = 'UPGRADE_TEACHER_LICENSE',
}

export enum StudentSpecificActionType {
  ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT',
  DEACTIVATE_STUDENT = 'DEACTIVATE_STUDENT',
  // RESEND_ACCOUNT_ACTIVATION = 'RESEND_ACCOUNT_ACTIVATION',
  RESEND_EMAIL_VERIFICATION = 'RESEND_EMAIL_VERIFICATION',
  CHANGE_STUDENT_PASSWORD = 'CHANGE_STUDENT_PASSWORD',
}

export type MisUserRoleType = 'teacher' | 'student';

export interface AnalyticsEvent {
  name?: AnalyticsEventName;
  options?: AnalyticsMetadata;
}

export type SSOType = 'Google' | 'Microsoft' | 'Feide';

export type LookerAnalyticsTab =
  | 'overview'
  | 'socrative'
  | 'students-parents'
  | 'classes'
  | 'schools'
  | 'teachers';

export interface AnalyticsMetadata {
  inArea?: AnalyticsAppArea;
  actionType?: AnalyticsActionType;
  count?: number;
  level?: InstitutionType;
  schoolId?: string;
  sortBy?: string;
  sortDir?: string;
  filterType?: string;
  feature?: string; // app__feature_explored
  proFeature?: string; // app__paywall_triggered
  signInType?: 'standard' | 'sso';
  ssoType?: SSOType;
  tab?: LookerAnalyticsTab;
  userType?: 'teachers' | 'students';
}

export type AnalyticsActionType = 'single' | 'bulk' | 'all';

export type AnalyticsAppArea =
  | 'home'
  | 'settings_admins'
  | 'students'
  | 'teachers_account'
  | 'teachers_classes'
  | 'teachers'
  | 'wizard_new_user_review'
  | 'wizard_summary';

export type AnalyticsEventName =
  | 'analytics__tab_accessed'
  | 'app__admin_tools'
  | 'app__analytics_clicked'
  | 'app__approve_teachers'
  | 'app__buy_license'
  | 'app__feature_explored'
  | 'app__help_center_opened'
  | 'app__home_clicked'
  | 'app__import_classes'
  | 'app__import_students'
  | 'app__import_teachers'
  | 'app__invite_teachers'
  | 'app__manage_licenses'
  | 'app__paywall_dismissed'
  | 'app__paywall_triggered'
  | 'app__scope_changed'
  | 'app__settings_clicked'
  | 'app__students_clicked'
  | 'app__teachers_clicked'
  | 'classes__code_copied'
  | 'login__forgot_password_clicked'
  | 'login__sign_in'
  | 'login__sign_up_clicked'
  | 'login__switch_to_showbie_clicked'
  | 'mis__user_wizard_cancelled'
  | 'mis__user_wizard_launched'
  | 'students__deactivated_student'
  | 'students__email_confirmation_resent'
  | 'students__password_changed'
  | 'students__qr_codes_exported'
  | 'students__reactivated_student'
  | 'table__filter_applied'
  | 'table__search'
  | 'table__sort'
  | 'teachers__account_activation_resent'
  | 'teachers__class_assigned'
  | 'teachers__deactivated_teacher'
  | 'teachers__email_confirmation_resent'
  | 'teachers__license_downgraded'
  | 'teachers__license_upgraded'
  | 'teachers__overview_clicked'
  | 'teachers__password_reset_sent'
  | 'teachers__reactivated_teacher'
  | 'user_profile__logout'
  | 'user_profile__opened'
  | 'user_profile__switch_to_showbie';

/**
 * This interface provides the basics of a JSON:API error object
 * which is what the Showbie Core Service returns.
 *
 * @see https://jsonapi.org/format/#errors
 * Look into https://github.com/mike-north/jsonapi-typescript.
 */
export interface JSONAPIError {
  title: string;
  code: string;
  status: string;
  meta?: Record<string, string>;
}
