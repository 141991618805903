import EmberRouter from '@embroider/router';
import config from 'dashboard/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  // WARNING: these route names are mapped in `service:analytics` and any
  // changes must be kept in sync.
  this.route('dashboard', { path: '/' }, function () {
    this.route('account', { path: 'a/:account_id' }, function () {
      this.route('teachers');
      this.route('teacher', { path: 'teachers/:teacher_id' }, function () {
        this.route('courses');
      });
      // legacy route; simply a redirect
      this.route('pending', { path: 'teachers/pending' });

      this.route('students');
      this.route('students-district', { path: '/students/-district' });
      this.route('analytics', function () {
        this.route('embed', { path: ':name' });
      });
      this.route('settings', function () {
        this.route('account-review-students');
        this.route('account-review-teachers');
      });
    });
  });

  // Shortcut Routes
  // These simply load the default account and redirect to the main routes.
  this.route('teachers');
  this.route('students');
  this.route('settings');

  this.route('login', { path: '/signin' });
  this.route('bling-mode', { path: '/bm/:bling_token' });
  this.route('not-found', { path: '/*path' });

  // OAuth/OIDC redirect pages
  this.route('oauth', function () {
    this.route('feide', function () {
      this.route('redirect');
    });
  });

  if (config.environment !== 'production') {
    // "Lunchbox"
    // This is the documentation and example usage for the Shoelace component design system.
    this.route('lbx', function () {
      this.route('avatar');
      this.route('badge');
      this.route('banner-alert');
      this.route('button');
      this.route('input');
      this.route('menu');
      this.route('select');
      this.route('toast');
    });
  }
});
