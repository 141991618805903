import Controller from '@ember/controller';
import { service } from '@ember/service';
import { queryManager } from 'ember-apollo-client';
import type { FeatureService } from 'ember-feature-flags/services/features';
import type { QueryManager } from '@apollo/client/core/QueryManager';
import type SessionService from 'dashboard/services/session';
import type CurrentUserService from 'dashboard/services/current-user';
import HttpAnalyticsService from 'dashboard/services/http-analytics';

export default class ApplicationController extends Controller {
  @queryManager declare apollo: QueryManager<Controller>;
  @service declare session: SessionService;
  @service declare features: FeatureService;
  @service declare currentUser: CurrentUserService;
  @service declare httpAnalytics: HttpAnalyticsService;
}
