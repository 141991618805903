import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import CustomSessionService from 'dashboard/services/session';
import CurrentUserService from 'dashboard/services/current-user';

export default class TeachersRoute extends Route {
  @service declare currentUser: CurrentUserService;
  @service declare router: RouterService;
  @service declare session: CustomSessionService;

  async beforeModel(transition: Transition): Promise<void> {
    this.session.requireAuthentication(transition, 'login');
    await this.currentUser.load();

    const { selectedPurchaserId } = this.currentUser;
    this.router.replaceWith('dashboard.account.teachers', selectedPurchaserId);

    // , {
    //   queryParams: { sortBy: 'lastName' },
    // });
    // , {
    //   queryParams: {
    //     after: '',
    //     before: '',
    //     count: '84',
    //     email: 'All',
    //     license: 'All',
    //     search: '',
    //     sortBy: 'lastName',
    //     sortDir: 'asc',
    //   }}
  }
}
