import Route from '@ember/routing/route';
import type RouteModel from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { PREVIOUS_AUTH_ERROR_KEY } from 'dashboard/constants';
import type LoginController from 'dashboard/controllers/login';
import type SessionService from 'dashboard/services/session';
import window from 'ember-window-mock';

export default class LoginRoute extends Route {
  @service declare session: SessionService;

  beforeModel(): void {
    this.session.prohibitAuthentication('dashboard');
  }

  /**
   * Check for a previous login error in sessionStorage and, if found,
   * trigger its display in the controller.
   */
  setupController(
    controller: LoginController,
    model: RouteModel,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);
    const previousError = window.sessionStorage.getItem(
      PREVIOUS_AUTH_ERROR_KEY
    );
    if (previousError) {
      controller.displayError(previousError);
      window.sessionStorage.removeItem(PREVIOUS_AUTH_ERROR_KEY);
    }
  }
}
