import { isTesting, isDevelopingApp, macroCondition } from '@embroider/macros';

/**
 * Include the Shoelace components that we are using in vendor bundle.
 * These map to the version of `@shoelace-style/shoelace` installed from NPM.
 *
 * The Shoelace CSS is imported in app.css (via postcss-import).
 *
 * Note that using `app.import` doesn't work for the JS modules. The approach
 * here effectively mimics the recommended bundling approach from the docs.
 *
 * The Shoelace icons *are not included*. If we ever need them (or other
 * Shoelace static assets), we can write a simple in-repo-addon that funnels
 * them into our public directory, using `treeForPublic`:
 *
 * ```js
 * let shoelacePath = path.dirname(require.resolve('@shoelace-style/shoelace'));
 * let shoelaceTree = new Funnel(`${shoelacePath}/assets`, { destDir: 'shoelace', });
 * ```
 *
 * TYPESCRIPT
 * All Shoelace components are typed. You can use these in your components as
 * needed by importing them explicitly. For example, the sl-dropdown component:
 *
 * ```
 * import type SlDropdown from '@shoelace-style/shoelace/dist/components/dropdown/dropdown';
 * ```
 *
 * See `node_modules/@shoelace-style/shoelace/dist/shoelace.d.ts` for more info.
 *
 * @see https://shoelace.style/getting-started/installation?id=bundling
 */
import '@shoelace-style/shoelace/dist/components/alert/alert.js';
import '@shoelace-style/shoelace/dist/components/avatar/avatar.js';
import '@shoelace-style/shoelace/dist/components/badge/badge.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/checkbox/checkbox.js';
import '@shoelace-style/shoelace/dist/components/dialog/dialog.js';
import '@shoelace-style/shoelace/dist/components/divider/divider.js';
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js';
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item.js';
import '@shoelace-style/shoelace/dist/components/menu/menu.js';
import '@shoelace-style/shoelace/dist/components/option/option.js';
import '@shoelace-style/shoelace/dist/components/progress-bar/progress-bar.js';
import '@shoelace-style/shoelace/dist/components/radio/radio.js';
import '@shoelace-style/shoelace/dist/components/radio-group/radio-group.js';
import '@shoelace-style/shoelace/dist/components/select/select.js';
import '@shoelace-style/shoelace/dist/components/switch/switch.js';
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';
import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js';

// only for lbx; exclude in prod
if (macroCondition(isDevelopingApp())) {
  import('@shoelace-style/shoelace/dist/components/copy-button/copy-button.js');
}

import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path.js';
import { setDefaultAnimation } from '@shoelace-style/shoelace/dist/utilities/animation-registry.js';
import { registerIconLibrary } from '@shoelace-style/shoelace/dist/utilities/icon-library.js';

/**
 * @see https://shoelace.style/getting-started/installation#setting-the-base-path
 */
setBasePath('/shoelace/dist');

/**
 * @see https://shoelace.style/components/icon/#icon-libraries
 */
registerIconLibrary('showbie', {
  resolver: (name) => {
    return `/assets/icons/${name}.svg`;
  },
  // mutator: (svg) => svg.setAttribute('fill', 'currentColor'),
});

/**
 * Omit Shoelace animation for all dropdowns in test environment.
 * This avoids some timing issues between Shoelace and the Ember test suite.
 *
 * @see https://shoelace.style/getting-started/customizing#animations
 * @see https://github.com/embroider-build/embroider/tree/main/packages/macros#istesting-isdevelopingapp
 */
if (macroCondition(isTesting())) {
  console.info('Detected Ember testing mode; disabling Shoelace animations.');
  setDefaultAnimation('dropdown.show', null);
  setDefaultAnimation('dropdown.hide', null);
  setDefaultAnimation('alert.show', null);
  setDefaultAnimation('alert.hide', null);
}
