import Service from '@ember/service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ENV from 'dashboard/config/environment';
import Analytics from 'analytics';
import { amplitudePlugin } from '@showbie/analytics';
import type { AnalyticsInstance } from 'analytics';
import type {
  AnalyticsAppArea,
  AnalyticsEventName,
  AnalyticsMetadata,
} from 'dashboard/types';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';

const {
  APP: { version },
} = ENV;

interface IAnalyticsAreaRouteMap {
  [key: string]: AnalyticsAppArea;
}
type AnalyticsAreaRouteKey = keyof typeof AnalyticsAreaRouteMap;

/** @see router.js */
const AnalyticsAreaRouteMap: IAnalyticsAreaRouteMap = {
  'dashboard.account.index': 'home',
  'dashboard.account.settings.index': 'settings_admins',
  'dashboard.account.settings.account-review': 'wizard_new_user_review',
  'dashboard.account.students': 'students',
  'dashboard.account.teacher.courses': 'teachers_classes',
  'dashboard.account.teacher.index': 'teachers_account',
  'dashboard.account.teachers': 'teachers',
} as const;

export default class AnalyticsService extends Service {
  @service declare router: RouterService;

  inArea: AnalyticsAppArea | null = null;

  constructor(...args: []) {
    super(...args);
    // listen for route changes and set inArea accordingly
    this.router.on('routeDidChange', (transition: Transition) => {
      const name = transition.to?.name ?? '';
      if (name in AnalyticsAreaRouteMap) {
        this.inArea = AnalyticsAreaRouteMap[name as AnalyticsAreaRouteKey];
      } else {
        this.inArea = null;
      }
    });
  }

  @tracked analytics: AnalyticsInstance = Analytics({
    // @see https://getanalytics.io/api/#configuration
    app: 'admin-dashboard',
    version,
    debug: !ENV.APP.analytics.enabled,
    plugins: ENV.APP.analytics.enabled
      ? [
          /** @see https://amplitude.com/docs/sdks/analytics/browser/browser-sdk-2 */
          amplitudePlugin({
            apiKey: ENV.APP.analytics.amplitudeApiKey,
            options: {
              // https://amplitude.com/docs/sdks/analytics/browser/browser-sdk-2#configure-the-sdk
              appVersion: version,
              // https://amplitude.com/docs/sdks/analytics/browser/browser-sdk-2#autocapture
              autocapture: false,
              // https://amplitude.com/docs/sdks/analytics/browser/browser-sdk-2#optional-tracking
              trackingOptions: {
                ip_address: false,
              },
            },
          }),
        ]
      : [],
  });

  track(eventName: AnalyticsEventName, payload?: AnalyticsMetadata) {
    if (this.inArea) {
      payload = payload || {};
      payload = {
        inArea: this.inArea,
        ...payload,
      };
    }
    return this.analytics.track(eventName, payload);
  }
}
