
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("dashboard/app", function(){ return i("dashboard/app.js");});
d("dashboard/authenticators/bling", function(){ return i("dashboard/authenticators/bling.ts");});
d("dashboard/authenticators/feide", function(){ return i("dashboard/authenticators/feide.ts");});
d("dashboard/authenticators/google", function(){ return i("dashboard/authenticators/google.ts");});
d("dashboard/authenticators/microsoft", function(){ return i("dashboard/authenticators/microsoft.ts");});
d("dashboard/authenticators/oauth2", function(){ return i("dashboard/authenticators/oauth2.ts");});
d("dashboard/authenticators/showbie", function(){ return i("dashboard/authenticators/showbie.ts");});
d("dashboard/config/environment", function(){ return i("dashboard/config/environment.js");});
d("dashboard/constants", function(){ return i("dashboard/constants.ts");});
d("dashboard/formats", function(){ return i("dashboard/formats.js");});
d("dashboard/gql/mutations/activate.gql", function(){ return i("dashboard/gql/mutations/activate.gql.js");});
d("dashboard/gql/mutations/assign-courses-to-teacher.gql", function(){ return i("dashboard/gql/mutations/assign-courses-to-teacher.gql.js");});
d("dashboard/gql/mutations/bulkInviteTeacherToSchool.gql", function(){ return i("dashboard/gql/mutations/bulkInviteTeacherToSchool.gql.js");});
d("dashboard/gql/mutations/connect-sync-school.gql", function(){ return i("dashboard/gql/mutations/connect-sync-school.gql.js");});
d("dashboard/gql/mutations/deactivate.gql", function(){ return i("dashboard/gql/mutations/deactivate.gql.js");});
d("dashboard/gql/mutations/disable-sync.gql", function(){ return i("dashboard/gql/mutations/disable-sync.gql.js");});
d("dashboard/gql/mutations/disconnect-sync-school.gql", function(){ return i("dashboard/gql/mutations/disconnect-sync-school.gql.js");});
d("dashboard/gql/mutations/enable-sync.gql", function(){ return i("dashboard/gql/mutations/enable-sync.gql.js");});
d("dashboard/gql/mutations/resend-activation-emails.gql", function(){ return i("dashboard/gql/mutations/resend-activation-emails.gql.js");});
d("dashboard/gql/mutations/resend-email-confirmation.gql", function(){ return i("dashboard/gql/mutations/resend-email-confirmation.gql.js");});
d("dashboard/gql/mutations/reset-password.gql", function(){ return i("dashboard/gql/mutations/reset-password.gql.js");});
d("dashboard/gql/mutations/set-sso-student-password.gql", function(){ return i("dashboard/gql/mutations/set-sso-student-password.gql.js");});
d("dashboard/gql/mutations/set-student-password.gql", function(){ return i("dashboard/gql/mutations/set-student-password.gql.js");});
d("dashboard/gql/mutations/start-school-sync.gql", function(){ return i("dashboard/gql/mutations/start-school-sync.gql.js");});
d("dashboard/gql/mutations/start-sign-in-qr-export-job.gql", function(){ return i("dashboard/gql/mutations/start-sign-in-qr-export-job.gql.js");});
d("dashboard/gql/mutations/teacherBulkAction.gql", function(){ return i("dashboard/gql/mutations/teacherBulkAction.gql.js");});
d("dashboard/gql/mutations/update-purchaser-settings.gql", function(){ return i("dashboard/gql/mutations/update-purchaser-settings.gql.js");});
d("dashboard/gql/queries/administrators.gql", function(){ return i("dashboard/gql/queries/administrators.gql.js");});
d("dashboard/gql/queries/analytics-embed.gql", function(){ return i("dashboard/gql/queries/analytics-embed.gql.js");});
d("dashboard/gql/queries/auditors.gql", function(){ return i("dashboard/gql/queries/auditors.gql.js");});
d("dashboard/gql/queries/courses-for-teacher.gql", function(){ return i("dashboard/gql/queries/courses-for-teacher.gql.js");});
d("dashboard/gql/queries/purchaser-settings.gql", function(){ return i("dashboard/gql/queries/purchaser-settings.gql.js");});
d("dashboard/gql/queries/purchaser-with-sync-details.gql", function(){ return i("dashboard/gql/queries/purchaser-with-sync-details.gql.js");});
d("dashboard/gql/queries/purchaser.gql", function(){ return i("dashboard/gql/queries/purchaser.gql.js");});
d("dashboard/gql/queries/school-sync-progress.gql", function(){ return i("dashboard/gql/queries/school-sync-progress.gql.js");});
d("dashboard/gql/queries/school-sync-stats.gql", function(){ return i("dashboard/gql/queries/school-sync-stats.gql.js");});
d("dashboard/gql/queries/sign-in-qr-export-job-status.gql", function(){ return i("dashboard/gql/queries/sign-in-qr-export-job-status.gql.js");});
d("dashboard/gql/queries/students.gql", function(){ return i("dashboard/gql/queries/students.gql.js");});
d("dashboard/gql/queries/sync-users.gql", function(){ return i("dashboard/gql/queries/sync-users.gql.js");});
d("dashboard/gql/queries/teacher.gql", function(){ return i("dashboard/gql/queries/teacher.gql.js");});
d("dashboard/gql/queries/teachers.gql", function(){ return i("dashboard/gql/queries/teachers.gql.js");});
d("dashboard/gql/queries/user.gql", function(){ return i("dashboard/gql/queries/user.gql.js");});
d("dashboard/gql/types.gql", function(){ return i("dashboard/gql/types.gql.js");});
d("dashboard/router", function(){ return i("dashboard/router.js");});
d("dashboard/services/-apollo", function(){ return i("dashboard/services/-apollo.ts");});
d("dashboard/services/-session", function(){ return i("dashboard/services/-session.ts");});
d("dashboard/services/analytics", function(){ return i("dashboard/services/analytics.ts");});
d("dashboard/services/apollo", function(){ return i("dashboard/services/apollo.js");});
d("dashboard/services/current-user", function(){ return i("dashboard/services/current-user.ts");});
d("dashboard/services/export-student-qr-codes", function(){ return i("dashboard/services/export-student-qr-codes.ts");});
d("dashboard/services/google-client", function(){ return i("dashboard/services/google-client.ts");});
d("dashboard/services/http-analytics", function(){ return i("dashboard/services/http-analytics.ts");});
d("dashboard/services/notify", function(){ return i("dashboard/services/notify.ts");});
d("dashboard/services/session", function(){ return i("dashboard/services/session.js");});
d("dashboard/services/toast", function(){ return i("dashboard/services/toast.ts");});
d("dashboard/services/typeform-manager", function(){ return i("dashboard/services/typeform-manager.ts");});
d("dashboard/session-stores/application", function(){ return i("dashboard/session-stores/application.ts");});
d("dashboard/shoelace", function(){ return i("dashboard/shoelace.js");});
d("dashboard/snippets", function(){ return i("dashboard/snippets.js");});
d("dashboard/types/index", function(){ return i("dashboard/types/index.ts");});
d("dashboard/types/shoelace-extras", function(){ return i("dashboard/types/shoelace-extras.ts");});
d("dashboard/utils/cycle-sort-options", function(){ return i("dashboard/utils/cycle-sort-options.ts");});
d("dashboard/utils/errors", function(){ return i("dashboard/utils/errors.ts");});
d("dashboard/utils/index", function(){ return i("dashboard/utils/index.ts");});
d("dashboard/utils/list-filter", function(){ return i("dashboard/utils/list-filter.ts");});
d("dashboard/utils/preferred-page-length", function(){ return i("dashboard/utils/preferred-page-length.js");});
d("dashboard/utils/sort-purchasers", function(){ return i("dashboard/utils/sort-purchasers.ts");});
d("dashboard/services/keyboard", function(){ return i("dashboard/services/keyboard.js");});
d("dashboard/services/page-title", function(){ return i("dashboard/services/page-title.js");});
d("dashboard/services/shiki", function(){ return i("dashboard/services/shiki.js");});
d("dashboard/services/cookies", function(){ return i("dashboard/services/cookies.js");});
d("dashboard/initializers/ember-simple-auth", function(){ return i("dashboard/initializers/ember-simple-auth.js");});
d("dashboard/session-stores/application", function(){ return i("dashboard/session-stores/application.js");});
d("dashboard/utils/inject", function(){ return i("dashboard/utils/inject.js");});
d("dashboard/utils/is-fastboot", function(){ return i("dashboard/utils/is-fastboot.js");});
d("dashboard/utils/location", function(){ return i("dashboard/utils/location.js");});
d("dashboard/utils/objects-are-equal", function(){ return i("dashboard/utils/objects-are-equal.js");});
d("dashboard/services/-portal", function(){ return i("dashboard/services/-portal.js");});
d("dashboard/component-managers/glimmer", function(){ return i("dashboard/component-managers/glimmer.js");});
d("dashboard/instance-initializers/sentry-performance", function(){ return i("dashboard/instance-initializers/sentry-performance.js");});
d("dashboard/initializers/app-version", function(){ return i("dashboard/initializers/app-version.js");});
d("dashboard/services/features", function(){ return i("dashboard/services/features.js");});
d("dashboard/services/intl", function(){ return i("dashboard/services/intl.js");});
d("dashboard/utils/intl/missing-message", function(){ return i("dashboard/utils/intl/missing-message.js");});
d("dashboard/container-debug-adapter", function(){ return i("dashboard/container-debug-adapter.js");});
d("dashboard/breakpoints", function(){ return i("dashboard/breakpoints.js");});
d("dashboard/initializers/ember-responsive-breakpoints", function(){ return i("dashboard/initializers/ember-responsive-breakpoints.js");});
d("dashboard/services/media", function(){ return i("dashboard/services/media.js");});
d("dashboard/modifiers/click-outside", function(){ return i("dashboard/modifiers/click-outside.js");});
d("dashboard/modifiers/css-transition", function(){ return i("dashboard/modifiers/css-transition.js");});
d("dashboard/modifiers/focus-trap", function(){ return i("dashboard/modifiers/focus-trap.js");});
d("dashboard/modifiers/on-key", function(){ return i("dashboard/modifiers/on-key.js");});
d("dashboard/modifiers/shiki-render", function(){ return i("dashboard/modifiers/shiki-render.js");});
d("dashboard/modifiers/did-insert", function(){ return i("dashboard/modifiers/did-insert.js");});
d("dashboard/modifiers/did-update", function(){ return i("dashboard/modifiers/did-update.js");});
d("dashboard/modifiers/will-destroy", function(){ return i("dashboard/modifiers/will-destroy.js");});
d("dashboard/modifiers/did-intersect", function(){ return i("dashboard/modifiers/did-intersect.js");});
d("dashboard/modifiers/scroll-into-view", function(){ return i("dashboard/modifiers/scroll-into-view.js");});
d("dashboard/templates/application", function(){ return i("dashboard/templates/application.hbs");});
d("dashboard/controllers/application", function(){ return i("dashboard/controllers/application.ts");});
d("dashboard/routes/application", function(){ return i("dashboard/routes/application.ts");});
d("dashboard/templates/login", function(){ return i("dashboard/templates/login.hbs");});
d("dashboard/controllers/login", function(){ return i("dashboard/controllers/login.ts");});
d("dashboard/routes/login", function(){ return i("dashboard/routes/login.ts");});
d("dashboard/routes/bling-mode", function(){ return i("dashboard/routes/bling-mode.ts");});
d("dashboard/routes/not-found", function(){ return i("dashboard/routes/not-found.ts");});
d("dashboard/routes/settings", function(){ return i("dashboard/routes/settings.ts");});
d("dashboard/routes/students", function(){ return i("dashboard/routes/students.ts");});
d("dashboard/routes/teachers", function(){ return i("dashboard/routes/teachers.ts");});

  if (macroCondition(getGlobalConfig().fastboot?.isRunning)) {
d("dashboard/instance-initializers/setup-fetch", function(){ return i("dashboard/instance-initializers/setup-fetch.js");});
  }


w._embroiderRouteBundles_ = [
  {
    names: ["dashboard.account.analytics.embed"],
    load: function() {
      return import("dashboard/assets/_route_/dashboard.account.analytics.embed.js");
    }
  },
  {
    names: ["dashboard.account.analytics.index"],
    load: function() {
      return import("dashboard/assets/_route_/dashboard.account.analytics.index.js");
    }
  },
  {
    names: ["dashboard.account.analytics"],
    load: function() {
      return import("dashboard/assets/_route_/dashboard.account.analytics.js");
    }
  },
  {
    names: ["dashboard.account.index"],
    load: function() {
      return import("dashboard/assets/_route_/dashboard.account.index.js");
    }
  },
  {
    names: ["dashboard.account.settings.account-review-students"],
    load: function() {
      return import("dashboard/assets/_route_/dashboard.account.settings.account-review-students.js");
    }
  },
  {
    names: ["dashboard.account.settings.account-review-teachers"],
    load: function() {
      return import("dashboard/assets/_route_/dashboard.account.settings.account-review-teachers.js");
    }
  },
  {
    names: ["dashboard.account.settings.account-review"],
    load: function() {
      return import("dashboard/assets/_route_/dashboard.account.settings.account-review.js");
    }
  },
  {
    names: ["dashboard.account.settings.index"],
    load: function() {
      return import("dashboard/assets/_route_/dashboard.account.settings.index.js");
    }
  },
  {
    names: ["dashboard.account.settings"],
    load: function() {
      return import("dashboard/assets/_route_/dashboard.account.settings.js");
    }
  },
  {
    names: ["dashboard.account.students"],
    load: function() {
      return import("dashboard/assets/_route_/dashboard.account.students.js");
    }
  },
  {
    names: ["dashboard.account.teacher.courses"],
    load: function() {
      return import("dashboard/assets/_route_/dashboard.account.teacher.courses.js");
    }
  },
  {
    names: ["dashboard.account.teacher.index"],
    load: function() {
      return import("dashboard/assets/_route_/dashboard.account.teacher.index.js");
    }
  },
  {
    names: ["dashboard.account.teacher"],
    load: function() {
      return import("dashboard/assets/_route_/dashboard.account.teacher.js");
    }
  },
  {
    names: ["dashboard.account.teachers"],
    load: function() {
      return import("dashboard/assets/_route_/dashboard.account.teachers.js");
    }
  },
  {
    names: ["dashboard.account.pending"],
    load: function() {
      return import("dashboard/assets/_route_/dashboard.account.pending.js");
    }
  },
  {
    names: ["dashboard.account.students-district"],
    load: function() {
      return import("dashboard/assets/_route_/dashboard.account.students-district.js");
    }
  },
  {
    names: ["dashboard.account"],
    load: function() {
      return import("dashboard/assets/_route_/dashboard.account.js");
    }
  },
  {
    names: ["dashboard.index"],
    load: function() {
      return import("dashboard/assets/_route_/dashboard.index.js");
    }
  },
  {
    names: ["dashboard"],
    load: function() {
      return import("dashboard/assets/_route_/dashboard.js");
    }
  },
  {
    names: ["lbx.avatar"],
    load: function() {
      return import("dashboard/assets/_route_/lbx.avatar.js");
    }
  },
  {
    names: ["lbx.badge"],
    load: function() {
      return import("dashboard/assets/_route_/lbx.badge.js");
    }
  },
  {
    names: ["lbx.banner-alert"],
    load: function() {
      return import("dashboard/assets/_route_/lbx.banner-alert.js");
    }
  },
  {
    names: ["lbx.button"],
    load: function() {
      return import("dashboard/assets/_route_/lbx.button.js");
    }
  },
  {
    names: ["lbx.index"],
    load: function() {
      return import("dashboard/assets/_route_/lbx.index.js");
    }
  },
  {
    names: ["lbx.input"],
    load: function() {
      return import("dashboard/assets/_route_/lbx.input.js");
    }
  },
  {
    names: ["lbx.menu"],
    load: function() {
      return import("dashboard/assets/_route_/lbx.menu.js");
    }
  },
  {
    names: ["lbx.select"],
    load: function() {
      return import("dashboard/assets/_route_/lbx.select.js");
    }
  },
  {
    names: ["lbx.toast"],
    load: function() {
      return import("dashboard/assets/_route_/lbx.toast.js");
    }
  },
  {
    names: ["lbx"],
    load: function() {
      return import("dashboard/assets/_route_/lbx.js");
    }
  },
  {
    names: ["oauth.feide.redirect"],
    load: function() {
      return import("dashboard/assets/_route_/oauth.feide.redirect.js");
    }
  },
]


if (!runningTests) {
  i("../app").default.create({"analytics":{"enabled":true,"amplitudeApiKey":"8ce685232b684acca6b5bf8f788469d3"},"authentication":{"enabled":true,"url":"https://adminstaging.showbie.com/core/v5/","showbie":{"clientApiKey":"ADMINDASHS62386a9456842d1606f586ed2468701684"},"google":{"clientId":"692872829681-btb142l4o60aouc34hql9ckran80aoos.apps.googleusercontent.com"},"microsoft":{"clientId":"eb091c3c-53d2-4435-8a20-5898f003b726","redirectUri":"https://adminstaging.showbie.com"},"feide":{"authority":"https://auth.dataporten.no","clientId":"1c7860e7-0beb-4dba-9cef-014c7e5b1ebc","redirectPath":"oauth/feide/redirect"}},"defaultAvatarUrl":"https://dp7ttp1cncmcz.cloudfront.net/images/shim.png","legacyDashboardUrl":"https://dashboardstaging.showbie.com","pagination":{"defaultPageLength":"20","pageLengthOptions":["20","60"]},"tooltipHideDelay":3000,"notifyHideDelay":4000,"name":"dashboard","version":"0.28.0+465a5b4c"});
}

