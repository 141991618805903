import SessionService from 'ember-simple-auth/services/session';
import { service } from '@ember/service';
import ENV from 'dashboard/config/environment';
import type { RegionResolutionResponse } from '@showbie/authentication';
import { shutdown } from '@intercom/messenger-js-sdk';
import { isTesting, macroCondition } from '@embroider/macros';
import type CurrentUserService from 'dashboard/services/current-user';
import type AnalyticsService from 'dashboard/services/analytics';

const { namespace, origin } = ENV.apiConfig.services.core;

export default class CustomSessionService extends SessionService {
  @service declare currentUser: CurrentUserService;
  @service declare analytics: AnalyticsService;

  apiOrigin = origin;
  apiNamespace = namespace;

  /**
   * Serves as a flag to trigger an OIDC Single Logout redirect after
   * the session has been invalidated.
   */
  oidcLogoutUrl: string | null;

  /**
   * Serves as a flag to display an error message on the login screen after
   * the session has been invalidated and the app has been reloaded.
   */
  previousError?: Error;

  get isBlingMode() {
    return this.data?.authenticated.authenticator === 'authenticator:bling';
  }

  /**
   * Should be used during the sign-in flow, after the user's region has been
   * resolved, to store the regional API origin. It will be used for all
   * subsequent API requests, assuming the `session.buildUrl` method is used.
   *
   * @param region Resolved region data
   */
  setApiRegion(region: RegionResolutionResponse): void {
    this.apiOrigin = region.origin;
  }

  /**
   * Build an API endpoint URL based on the current origin and namespace.
   * Allows setting the origin dynamically based on the user's data region
   * which will be resolved during authentication.
   *
   * @param path Path of the endpoint to call
   * @param namespace Namespace of the request; useful for prefixing an API version.
   * @returns {string}
   */
  buildUrl(path = '', namespace = this.apiNamespace): string {
    const origin = this.apiOrigin || '';

    path = path.replace(/^\//, ''); // strip leading slash if path includes it

    let url = [origin, namespace, path].filter(Boolean).join('/');

    // if url does not start with https or /, prepend /
    if (!/^\//.test(url) && !/^https/.test(url)) {
      url = '/' + url;
    }

    return url;
  }

  /**
   * Intercept session invalidations and decide how to proceed.
   * In most cases, we simply allow the default ESA behaviour to proceed.
   * This simply reboots the application from scratch to the index route.
   *
   * However, in certain situations, we want to trigger a different flow.
   * Note that at this point the local session has already been invalidated
   * and the authenticated session data has been cleared.
   *
   * @see app/authenticators/feide.ts
   * @see https://ember-simple-auth.com/api/classes/SessionService.html#method_handleInvalidation
   * @see https://github.com/mainmatter/ember-simple-auth/blob/a7e583cf4d04d6ebc96b198a8fa6dde7445abf0e/packages/ember-simple-auth/addon/services/session.js#L369
   */
  handleInvalidation(routeAfterInvalidation: string) {
    if (macroCondition(!isTesting())) {
      // shutdown Intercom properly
      // https://www.npmjs.com/package/@intercom/messenger-js-sdk#methods
      shutdown();
      // reset analytics (Amplitude)
      this.analytics.analytics.reset();
    }

    const oidcLogoutUrl = this.oidcLogoutUrl;
    if (oidcLogoutUrl) {
      window.location.replace(oidcLogoutUrl);
    } else {
      // trigger the default ESA behaviour, which simply reloads the app from scratch
      super.handleInvalidation(routeAfterInvalidation);
    }
  }
}
