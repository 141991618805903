import { getOwner } from '@ember/owner';
import Service from '@ember/service';
import { service } from '@ember/service';
import { debug } from '@ember/debug';
import { loadScript } from '@showbie/utilities';
import ENV from 'dashboard/config/environment';
import type SessionService from 'dashboard/services/session';
import type LoginController from 'dashboard/controllers/login';

export default class GoogleClientService extends Service {
  @service declare session: SessionService;

  /** Whether the Google Sign-In Web client has been initialized. */
  private _siwgInitialized = false;

  initialize() {
    if (this._siwgInitialized) return Promise.resolve();

    // dynamically load the Google Sign-In client
    return loadScript({ src: 'https://accounts.google.com/gsi/client' })
      .then(() => this.handleGoogleScriptLoad())
      .catch((e) => {
        console.error('Error loading Google (GSI) client', e);
      });
  }

  /**
   * @see https://developers.google.com/identity/gsi/web/guides/use-one-tap-js-api
   * @see https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.initialize
   */
  handleGoogleScriptLoad(): void {
    window.google.accounts.id.initialize({
      client_id: ENV.APP.authentication.google.clientId,
      callback: this.handleGoogleCredentials.bind(this),
    });
    this._siwgInitialized = true;
  }

  /**
   * Render the Google Sign-In button.
   * If the Google Sign-In Web client has not been initialized, wait for it to
   * initialize before rendering the button.
   *
   * @param {String} id The HTML ID of the button container.
   * @see https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.renderButton
   */
  async renderButton(id: string) {
    if (!this._siwgInitialized) {
      debug('gsi/client not initialized; waiting to rendering button');
      await this.initialize();
    }

    const options: google.accounts.id.GsiButtonConfiguration = {
      type: 'standard',
      theme: 'outline',
      size: 'large',
      text: 'continue_with',
      /**
       * @see https://github.com/abacritt/angularx-social-login/issues/720
       * @see https://github.com/MomenSherif/react-oauth/issues/277
       */
      width: 336,
    };

    // @see https://developers.google.com/identity/gsi/web/reference/js-reference#GsiButtonConfiguration
    window.google.accounts.id.renderButton(
      document.getElementById(id) as HTMLElement,
      options
    );
  }

  /**
   * Capture the CredentialResponse from Google and decode it.
   * @see https://developers.google.com/identity/gsi/web/reference/js-reference#CredentialResponse
   * @see https://developers.google.com/identity/gsi/web/guides/handle-credential-responses-js-functions
   */
  async handleGoogleCredentials(
    response: google.accounts.id.CredentialResponse
  ): Promise<void> {
    // Not ideal to use the container to get the controller, but this is the
    // simplest approach for now and just a one-off situation
    const LoginController = getOwner(this)?.lookup(
      'controller:login'
    ) as LoginController;
    try {
      await this.session.authenticate('authenticator:google', response);
      LoginController.track('Google');
    } catch (error) {
      LoginController?.displayError(error);
    }
  }
}
