/* istanbul ignore file */
import BaseAuthenticator from 'ember-simple-auth/authenticators/base';
import ENV from 'dashboard/config/environment';
import fetch from 'fetch';
import { v4 as uuidv4 } from 'uuid';
import { service } from '@ember/service';
import HttpAnalyticsService, {
  type ShowbieFetchHeaders,
} from 'dashboard/services/http-analytics';
import { encode } from 'js-base64';
import { isEmpty } from '@ember/utils';
import RSVP from 'rsvp';
import type {
  ShowbieSessionData,
  UserAuthenticationCredentials,
} from 'dashboard/types';
import { resolveRegion } from '@showbie/authentication';
import type SessionService from 'dashboard/services/session';
import type { RegionResolutionData } from '@showbie/authentication';
import { ShowbieServiceError } from 'dashboard/utils/errors';

const { auth } = ENV.apiConfig.services;

export default class ShowbieAuthenticator extends BaseAuthenticator {
  @service declare httpAnalytics: HttpAnalyticsService;
  @service declare session: SessionService;

  async authenticate({
    userId,
    password,
  }: UserAuthenticationCredentials): Promise<ShowbieSessionData> {
    const credentials = encode(`${userId}:${password}`);
    const fingerprint = uuidv4();
    const headers = this.httpAnalytics.headers;
    const regionData: RegionResolutionData = {
      contextType: 'user',
      context: userId,
    };
    const opts = { headers: { ...headers } as ShowbieFetchHeaders };

    // resolve and store the regional origin
    const region = await resolveRegion(regionData, opts, auth.origin);
    this.session.setApiRegion(region);

    // create a new session in the appropriate region
    const resp: Response = await fetch(this.session.buildUrl('/sessions'), {
      method: 'POST',
      headers: {
        Authorization: `Basic ${credentials}`,
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify({
        session: { fingerprint },
      }),
    });

    const data = await resp.json();

    if (!resp.ok) {
      throw new ShowbieServiceError(data.errors);
    }

    return {
      ...data.session,
      region,
      fingerprint,
    };
  }

  restore(data: ShowbieSessionData): Promise<ShowbieSessionData> {
    // Compatibility with legacy dashboard sessions, restore with `token`
    return new RSVP.Promise((resolve, reject) => {
      if (!isEmpty(data) && !isEmpty(data.token)) {
        if (data.region && data.region.origin) {
          this.session.setApiRegion(data.region);
        }
        return resolve(data);
      }
      return reject('Could not restore session - "token" missing.');
    });
  }

  /**
   * Invalidate the server session and always resolve so that the local session
   * is cleared, suppressing any errors along the way.
   * @param data
   * @returns Promise
   */
  async invalidate(data: ShowbieSessionData): Promise<unknown> {
    const userToken = `sbe token=${encode(data.token)},fp=${encode(
      data.fingerprint
    )}`;

    const headers = this.httpAnalytics.headers;
    const url = this.session.buildUrl(`/sessions/${data.token}`);

    try {
      await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: userToken,
          'Content-Type': 'application/json',
          ...headers,
        },
      });

      return RSVP.resolve();
    } catch (reason) {
      console.error(reason);
      return RSVP.resolve();
    }
  }
}
