import Service from '@ember/service';
import { QueryManager } from '@apollo/client/core/QueryManager';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { SlAlert } from '@shoelace-style/shoelace';
import { task, timeout } from 'ember-concurrency';
import { IntlService } from 'ember-intl';

import startSignInQRExportJob from 'dashboard/gql/mutations/start-sign-in-qr-export-job.gql';
import signInQRExportJobStatus from 'dashboard/gql/queries/sign-in-qr-export-job-status.gql';
import type { School } from 'dashboard/types';
import type NotifyService from 'dashboard/services/notify';

type SignInQRExportJobStatus = {
  status: 'Q' | 'A' | 'C' | 'E';
  jobId: string;
  reportUrl?: string;
  totalStudents?: number;
  totalPages?: number;
};

const POLLING_DELAY = 1000;

export default class ExportStudentQrCodesService extends Service {
  @service declare apollo: QueryManager<Service>;
  @service declare intl: IntlService;
  @service declare notify: NotifyService;

  @tracked purchaser: School;

  /**
   * Start a QR xeport job for the given purchaser.
   */
  startJob(purchaser: School) {
    if (!purchaser) return;
    this.purchaser = purchaser;
    return this.exportQRCodesTask.perform(purchaser);
  }

  /**
   * Manage a QR export job: kick it off, polling for updates, manage toast
   * notifications and errors accordingly.
   */
  exportQRCodesTask = task({ drop: true }, async (purchaser: School) => {
    let progressAlert: SlAlert;
    let initialResult;
    let finalResult;

    try {
      initialResult = await this.startMutation.perform(purchaser.id);

      const count = initialResult?.totalStudents ?? 0;
      progressAlert = this.#createToast(
        `${this.intl.t('Getting {count} QR Codes', { count })}`
      );

      finalResult = await this.pollingTask.perform(initialResult.jobId);
    } catch (cause) {
      this.notify.feedback('SIGN_IN_QR_EXPORT_ERROR');
      return Promise.reject(cause);
    }

    progressAlert?.hide();

    const reportMessage = this.intl.t('{schoolName} Student QR Codes.pdf', {
      schoolName: this.purchaser.name,
    });
    const reportLink = `<a href="${finalResult.reportUrl}" target='_blank' rel='noreferrer noopener' download>${reportMessage}</a>`;
    this.#createToast(reportLink, 'success');

    return finalResult;
  });

  /**
   * Start the export job via GraphQL mutation.
   */
  startMutation = task(async (purchaserId: string) => {
    const result = await this.apollo.mutate(
      {
        mutation: startSignInQRExportJob,
        variables: { purchaserId },
      },
      // @ts-expect-error how to type?
      'startSignInQRExportJob'
    );

    return result as SignInQRExportJobStatus;
  });

  /**
   * Poll the service for updates to a specific export job.
   * This task starts an infinte loop and breaks out when it detects that the
   * job has completed or that the service has reported an error.
   */
  pollingTask = task(async (jobId: string) => {
    let result: SignInQRExportJobStatus;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      result = (await this.#pollServerForChanges(
        jobId
      )) as unknown as SignInQRExportJobStatus;

      // detect completed status
      if (result?.status === 'C') {
        break;
      }

      // detect error
      if (result?.status === 'E') {
        throw new Error('SignInQrExportJobStatus failed');
      }
      await timeout(POLLING_DELAY);
    }
    return result;
  });

  /**
   * Query the status of a QR export job via GraphQL.
   */
  async #pollServerForChanges(jobId: string) {
    const result = await this.apollo.query(
      {
        query: signInQRExportJobStatus,
        variables: { jobId },
        fetchPolicy: 'network-only',
      },
      'signInQRExportJobStatus'
    );

    return result;
  }

  #createToast(message: string, state = '') {
    let icon;

    switch (state) {
      case 'error':
        icon = `<sl-icon library="showbie" name="no-entry" slot="icon" class="w-8 h-8 text-red-600"></sl-icon>`;
        break;
      case 'success':
        icon = `<sl-icon library="showbie" name="check" slot="icon" class="w-8 h-8 text-green-600"></sl-icon>`;
        break;
      default:
        icon = `<sl-spinner slot='icon'></sl-spinner>`;
    }

    const alert = Object.assign(document.createElement('sl-alert'), {
      variant: state === 'error' ? 'danger' : 'primary',
      closable: true,
    });

    alert.innerHTML = `${icon} ${message}`;
    alert.classList.add('lbx-toast');

    document.body.append(alert);
    alert.toast();

    return alert;
  }
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('service:export-student-qr-codes')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('export-student-qr-codes') declare altName: ExportStudentQrCodesService;`.
declare module '@ember/service' {
  interface Registry {
    'export-student-qr-codes': ExportStudentQrCodesService;
  }
}
