import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type SessionService from 'dashboard/services/session';
import type RouterService from '@ember/routing/router-service';
import type { BlingModeParams } from 'dashboard/types';

/**
 * This route is how we boot a BlingMode session created from the internal
 * Bling app. The path looks like this: `/bm/some-random-token-here`.
 *
 * If there is a current, non-BlingMode, session, the BlingMode token will
 * not be processed. See the `beforeModel` hook below.
 *
 * If there's no current session, use `authenticator:bling` to boot the app.
 */
export default class BlingModeRoute extends Route {
  @service declare session: SessionService;
  @service declare router: RouterService;

  /**
   * BlingMode tokens can be used to overwrite an existing *BlingMode* session
   * but not a regular session. Here, we check skip out of processing a
   * BlingMode token if there is an existing *regular* session and simply
   * boot the app normally (restore existing session).
   *
   * This means the internal Bling users must sign out of any existing Dashboard
   * sessions before using BlingMode.
   *
   * However, if the existing session is a *BlingMode* session, a new token can
   * be used to clobber the current one. This allows internal Bling users to
   * launch BlingMode for various schools/districts without signing out between
   * each of them, making for a much smoother frictionless experience.
   */
  beforeModel(): void {
    if (this.session.isAuthenticated && !this.session.isBlingMode) {
      this.router.replaceWith('dashboard');
    }
  }

  /**
   * Authenticate the app with the provided BlingMode token.
   * Note that a new token can be used to overwrite an existing BlingMode
   * session and the most recent token takes precedence. See `beforeModel`.
   *
   * Note that `bling_token` will always exist at this point, otherwise this
   * route would not match (dynamic segment required: `/bm/:bling_token`).
   */
  async model({ bling_token: blingToken }: BlingModeParams): Promise<void> {
    await this.session.authenticate('authenticator:bling', blingToken);
    this.router.replaceWith('dashboard');
  }
}
